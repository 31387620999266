<template>
  <div>
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="row">
          <div class="col-12">
            <empresa-combo
              :selecionar="selectEmpresa"
              :selected="empresaSelecionada.cod_empresa"
            />
          </div>
          <div class="col-12 col-sm-6">
            <div style="">
              <label>Código</label>
              <b-input
                type="number"
                autocomplete="off"
                v-model="pedido.id_sistema"
                ref="txtCodigo"
                :class="{
                  'text-danger': retornoID && retornoID.qt && retornoID.qt > 0,
                }"
                @keypress="
                  (e) => {
                    if (e.key == 'Enter') {
                      this.gotoFocus('txtTotal');
                    }
                  }
                "
              />
              <div v-if="loadingID">
                <b-spinner
                  small
                  variant="secondary"
                />
                aguarde...
              </div>
              <div
                v-if="
                  !loadingID && retornoID && retornoID.qt && retornoID.qt > 0
                "
                class="text-danger"
              >
                Número já utilizado
              </div>
            </div>
            <div>
              <div class="mt-2 tipo-pedido">
                <label
                  :class="{ active: tipo_entrega == opc }"
                  @click="escolherTipoEntrega(opc)"
                  v-for="opc in opcoes_entrega"
                  :key="opc.titulo + opcoes_entrega.indexOf(opc)"
                  style="width: 42%"
                >
                  <img
                    :src="require(`@/assets/img/icones/pedido/${opc.img}`)"
                  />
                  <br />
                  {{ opc.titulo }}
                </label>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div style="">
              <label>Total</label>
              <input
                class="form-control text-center"
                autocomplete="off"
                v-money="money"
                v-model="pagamento.vl_total"
                ref="txtTotal"
                @focus="focus('txtTotal')"
                @keypress="
                  (e) => {
                    if (e.key == 'Enter') {
                      this.gotoFocus('txtEntrega');
                    }
                  }
                "
              />
            </div>
            <div style="">
              <label>Entrega</label>

              <input
                v-money="money"
                v-model="pagamento.vl_entrega"
                @focus="focus('txtEntrega')"
                autocomplete="off"
                ref="txtEntrega"
                class="form-control text-center"
                readonly
                @keypress="
                  (e) => {
                    if (e.key == 'Enter') {
                      this.gotoFocus('vlDim');
                    }
                  }
                "
              />
              <!-- <b-input-group>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  @click="pagamento.vl_entrega = '4,00'"
                  >4,00</b-button
                >
                <b-button
                  size="sm"
                  variant="outline-primary"
                  @click="pagamento.vl_entrega = '9,00'"
                  >9,00</b-button
                >
              </b-input-group> -->
            </div>
          </div>
          <div class="col-12 mb-3">
            <div>
              <label>Nome</label>
              <!-- <b-input v-model="pedido.bairro" /> -->
              <b-input
                v-model="pedido.nome"
                ref="txtNome"
              />
            </div>
          </div>
          <div class="col-12 mb-3">
            <div>
              <label>Bairro</label>
              <!-- <b-input v-model="pedido.bairro" /> -->

              <vue-simple-suggest
                v-model="pedido.bairro"
                :list="simpleSuggestionList"
                :styles="autoCompleteStyle"
                :destyled="false"
                :filter-by-query="true"
                :max-suggestions="5"
                @keypress.native="
                  (e) => {
                    if (e.key == 'Enter') {
                      this.gotoFocus('txtComp');
                    }
                  }
                "
              >
              </vue-simple-suggest>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div>
              <label>Complemento</label>
              <b-input
                v-model="pedido.complemento"
                ref="txtComp"
              />
            </div>
          </div>

          <div
            class="col-12 mb-3"
            v-if="pedido.cupomFiscal"
          >
            <div>
              <label>CPF/CNPJ</label>
              <b-input
                v-model="pedido.cpf"
                ref="txtCPF"
                v-mask="['###.###.###-##', '##.###.###/####-##']"
                @blur="validaCPF"
                :state="cpfValido"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <forma-pagamento
          :pagamento="pagamento"
          :semPagamento="pedido.tipo_entrega == 'BUSCAR'"
        />
        <div class="row mt-2">
          <div class="col-12">
            <label>Observações</label>
            <label class="float-right">
              <b-check
                switch
                v-model="pedido.alerta"
              >
                Notificar
              </b-check>
            </label>
            <textarea
              v-model="pedido.observacoes"
              class="form-control"
            />
          </div>
        </div>
        <div
          class="titulo mt-4"
          v-if="
            pedido &&
            pedido.cod_pedido &&
            ($store.state.auth.user.tipo.indexOf('Admin') >= 0 ||
              $store.state.auth.user.tipo.indexOf('Gerente') >= 0)
          "
        >
          <div class="texto pl-2">
            <i class="fas fa-ban"></i> Problema no Pedido
          </div>
          <div
            class="combo"
            style="max-width: 160px"
          >
            <b-select
              :options="[
                '',
                'Não Atende',
                'Pedido Errado',
                'Reclamou Temperatura',
                'Faltou Itens',
                'Outros',
              ]"
              v-model="pedido.motivo_erro"
            ></b-select>
          </div>
        </div>

        <div
          class="titulo mt-4"
          v-if="
            pedido &&
            pedido.cod_pedido &&
            ($store.state.auth.user.tipo.indexOf('Admin') >= 0 ||
              $store.state.auth.user.tipo.indexOf('Gerente') >= 0)
          "
        >
          <div class="texto pl-2"><i class="fa fa-motorcycle"></i> Moto</div>
          <div
            class="combo d-flex"
            style="max-width: 260px"
          >
            <b-select
              v-model="pedido.cod_moto"
              :options="motosDisponiveis"
              @change.native="(e) => selectValorMoto(e)"
              label="nome"
            />
            <b-btn
              variant="none"
              size="sm"
              @click="$bvModal.show('modal-view-motos')"
              ><b-icon-search
            /></b-btn>
            <b-modal
              id="modal-view-motos"
              hide-footer
              size="xl"
              @hidden="carregarMotos"
            >
              <EntregasMotos
                :data="pedido.data"
                :empresa="empresaSelecionada"
              />
            </b-modal>
          </div>
        </div>
        <div
          class="titulo mt-4"
          v-if="
            pedido &&
            pedido.cod_pedido &&
            ($store.state.auth.user.tipo.indexOf('Admin') >= 0 ||
              $store.state.auth.user.tipo.indexOf('Gerente') >= 0)
          "
        >
          <div class="texto pl-2">
            <i class="fa fa-motorcycle"></i> Valor Pg. Moto
          </div>
          <div
            class="combo"
            style="max-width: 100px"
          >
            <input
              v-money="money"
              v-model="pedido.vl_entrega_moto"
              @focus="focus('txtVlEntregaMoto')"
              autocomplete="off"
              ref="txtVlEntregaMoto"
              class="form-control text-center"
              @keypress="
                (e) => {
                  if (e.key == 'Enter') {
                    this.gotoFocus('txtVlEntregaMoto');
                  }
                }
              "
            />
          </div>
        </div>
        <b-btn
          block
          variant="danger"
          @click="removerProblema"
          v-if="pedido && pedido.pedido_erro && pedido.pedido_erro == 1"
          ><i class="fa fa-times mr-1"></i>Remover Problema</b-btn
        >
      </div>
    </div>
    <hr />
    <div class="row mt-3">
      <div class="col-12 col-lg-5 text-right d-flex">
        <b-check
          switch
          size="lg"
          v-model="pedido.imprimir"
          value="true"
          v-if="!pedidoOpen"
        >
          Imprimir
        </b-check>
        <b-check
          switch
          size="lg"
          v-model="pedido.cupomFiscal"
          value="true"
          v-if="!pedidoOpen"
          class="ml-3"
        >
          Cupom Fiscal
        </b-check>
        <print-buttons
          class="text-left"
          :noStyle="true"
          v-if="pedidoOpen"
          :codPedido="pedidoOpen.cod_pedido"
          :empresa="pedidoOpen.cod_empresa"
        />
      </div>
      <div class="col-12 col-lg-3 mt-2 mt-lg-0">
        <b-btn
          block
          variant="info"
          v-if="
            pedido && pedido.cod_pedido && pedido.cod_pedido != '' && !authAdm
          "
          @click="solicitarLoginAdm"
          ><b-icon-file-lock2 /> Autorização ADM</b-btn
        >
        <b-btn
          variant="danger"
          block
          @click="cancelarPedido"
          v-if="pedido && pedido.cod_pedido && pedido.cod_pedido != ''"
          ><b-icon-trash /> Cancelar Pedido</b-btn
        >
        <b-button
          class="mt-2"
          block
          @click="verLog()"
          v-if="pedido && pedido.cod_pedido > 0"
        >
          <b-icon-clock-history class="mr-1" />
          Alterações
        </b-button>
      </div>
      <div class="col-12 col-lg-4 text-right mt-2 mt-lg-0">
        <b-button
          variant="primary"
          @click="salvar"
          :disabled="loadingSave"
          class="w-50"
        >
          <span v-if="loadingSave"
            ><b-spinner
              small
              size="sm"
            />
            salvando...
          </span>
          <span v-else><i class="fa fa-save" /> Salvar</span>
        </b-button>
        <b-button
          variant="secondary"
          @click="
            () => {
              this.reset();
              this.$emit('closeModal');
            }
          "
          class="w-50"
        >
          <i class="fa fa-ban" />
          Fechar
        </b-button>
        <b-btn
          variant="info"
          block
          class="mt-2"
          @click="openCupom(pedido)"
          v-if="pedido.cod_pedido"
          ><i class="fa fa-receipt" /> Cupom Fiscal</b-btn
        >
      </div>
    </div>
    <login-admin
      v-if="loginAdm"
      @result="loginResult"
      :idExterno="pedido ? pedido.cod_pedido : null"
      :tipo="'pedido'"
      :obs="
        pedido && pedido.cod_pedido
          ? 'Edição de pedido'
          : 'Autorização de pedido novo'
      "
      @setSolicitacaoID="solicitacao_id = $event"
    />
    <b-modal
      hide-footer
      id="modal-log"
      size="xl"
      title="Histórico de Alterações"
    >
      <pedido-log :pedido="pedido"></pedido-log>
    </b-modal>
    <b-modal
      id="modal-fiscal"
      title="Cupom Fiscal"
      hide-footer
      v-if="CupomOpen"
      size="xl"
    >
      <CupomFiscal
        :PedidoID="CupomOpen.cod_pedido"
        :CodEmpresa="CupomOpen.cod_empresa"
      />
    </b-modal>
  </div>
</template>

<script>
import FormaPagamento from "./FormaPagamento.vue";
import PedidoLib from "../../libs/Pedido";
import EmpresaCombo from "../empresa/EmpresaCombo.vue";
import PrintButtons from "./PrintButtons.vue";
import VueSimpleSuggest from "vue-simple-suggest/lib";
import "vue-simple-suggest/dist/styles.css";
import moment from "moment";
import LoginAdmin from "../LoginAdmin.vue";
import PedidoLog from "./PedidoLog.vue";
import EntregasLib from "../../libs/Entregas";
import EntregasMotos from "../../views/Entregas/EntregasMotos.vue";
import CupomFiscal from "../Caixas/CupomFiscal.vue";
import validador from "../../utils/validador";
export default {
  props: {
    pedidoOpen: {
      type: Object,
      default: null,
    },
  },
  components: {
    FormaPagamento,
    EmpresaCombo,
    PrintButtons,
    VueSimpleSuggest,
    LoginAdmin,
    PedidoLog,
    EntregasMotos,
    CupomFiscal,
  },
  data() {
    return {
      CupomOpen: null,
      loadingSave: false,
      simpleSuggestionList: this.$store.state.enderecos.bairros.map((b) => {
        return b.bairro;
      }),
      autoCompleteStyle: {
        vueSimpleSuggest: "position-relative",
        inputWrapper: "",
        defaultInput: "form-control",
        suggestItem: "list-group-item",
        suggestions: "list-group",
        maxSuggestions: 2,
      },

      loadingID: false,
      retornoID: "",
      br: { value: "", text: "" },
      pedido: {
        cupomFiscal: false,
        cpf: "",

        id_sistema: "",
        tipo_entrega: "",
        bairro: "",
        nome: "",
        cod_pedido: "",
        cod_empresa: this.$store.state.empresa_ativa.cod_empresa,
        cod_caixa: this.$store.state.caixa_ativo.cod_caixa,
        senha: "",
        imprimir: true,
        complemento: "",
        pedido_erro: null,
        motivo_erro: null,
        alerta: false,
        observacoes: "",
        cod_moto: null,
        vl_entrega_moto:
          this.$store.state.empresa_ativa.configuracoes &&
          this.$store.state.empresa_ativa.configuracoes.frete_pg_online
            ? this.$store.state.empresa_ativa.configuracoes.frete_pg_online
            : 0,
      },
      // bairros: this.$store.state.enderecos.bairros.map((b)=>{ return {value:b.bairro, label:b.bairro}}),
      tipo_entrega: null,
      erros: [],
      pagamento: {
        vl_dinheiro: "0,00",
        vl_total: "0,00",
        vl_entrega: this.vl_entrega_padrao,
        vl_cartao: "0,00",
        vl_troco: "0,00",
        vl_pago: "0,00",
        forma: 0,
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: true /* doesn't work with directive */,
      },
      opcoes_entrega: [
        {
          titulo: "BUSCAR",
          img: "handle_with_care_52px.png",
        },
        {
          titulo: "ENTREGA",
          img: "delivery_scooter_52px.png",
        },
      ],
      authAdm:
        this.$store.state.auth.user.tipo.indexOf("Admin") >= 0 ||
        this.$store.state.auth.user.tipo.indexOf("Gerente") >= 0,
      solicitacao_id: null,
      loginAdm: false,
      vl_entrega_padrao:
        this.$store.state.empresa_ativa &&
        this.$store.state.empresa_ativa.configuracoes
          ? this.$store.state.empresa_ativa.configuracoes.frete_online
          : "R$ 10,00",
      empresaSelecionada: this.$store.state.empresa_ativa,
      motosDisponiveisRaw: [],
      motosDisponiveis: [],
      cpfValido: null,
    };
  },
  mounted() {
    this.reset();
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.$props.pedidoOpen) {
          console.log("OPEN 2", this.pedidoOpen);
          this.pedido.cod_pedido = this.pedidoOpen.cod_pedido;
          this.pedido.id_sistema = this.pedidoOpen.id_sistema;
          this.pedido.cod_empresa = this.pedidoOpen.cod_empresa;
          this.pedido.tipo_entrega = this.pedidoOpen.tipo_entrega;
          this.pedido.observacoes = this.pedidoOpen.observacoes;
          this.pedido.pedido_erro = this.pedidoOpen.pedido_erro;
          this.pedido.motivo_erro = this.pedidoOpen.motivo_erro;
          this.pedido.nome =
            this.pedidoOpen.cliente &&
            this.pedidoOpen.cliente.Nome &&
            this.pedidoOpen.cliente.Nome.indexOf("-") > 0
              ? this.pedidoOpen.cliente.Nome.split("-")[1].trim()
              : "";
          this.pedido.data = this.pedidoOpen.data;
          this.tipo_entrega = this.opcoes_entrega.find(
            (x) => x.titulo == this.pedidoOpen.tipo_entrega
          );
          this.pagamento.vl_total = this.toMoney(this.pedidoOpen.vl_total);
          this.pagamento.vl_dinheiro = this.toMoney(
            this.pedidoOpen.vl_dinheiro
          );
          this.pagamento.vl_cartao = this.toMoney(this.pedidoOpen.vl_cartao);
          this.pagamento.vl_entrega = this.toMoney(this.pedidoOpen.vl_entrega);
          let conta = this.pedidoOpen.tipo_entrega == "BUSCAR" ? 0 : -1;

          this.pagamento.forma =
            this.pedidoOpen.pagamento == "Dinheiro"
              ? conta + 1
              : this.pedidoOpen.pagamento == "Cartão"
              ? conta + 2
              : this.pedidoOpen.pagamento == "Dim. + Cart."
              ? conta + 3
              : this.pedidoOpen.pagamento == "Vale"
              ? conta + 4
              : this.pedidoOpen.pagamento == "Online"
              ? conta + 5
              : this.pedidoOpen.pagamento == "Só Entregar"
              ? conta + 6
              : this.pedidoOpen.pagamento == "PIX"
              ? conta + 7
              : 0;

          this.pedido.senha = this.pedidoOpen.senha;
          this.pedido.bairro = this.pedidoOpen.cliente.Bairro;
          this.pedido.complemento = this.pedidoOpen.cliente.complemento;
          this.pedido.imprimir = false;

          this.pedido.vl_entrega_moto = this.formatFloatToReal(
            this.pedidoOpen.vl_entrega_moto || 0
          );

          this.pedido.cod_moto = this.pedidoOpen.cod_moto;
          // console.log(this.pedido, this.pagamento);
          this.$forceUpdate();
          this.carregarMotos();
        }
      }, 100);
    });
    setTimeout(() => {
      this.$refs.txtCodigo.focus();
      this.$refs.txtCodigo.select();
    }, 300);
  },
  watch: {
    pagamento: {
      deep: true,
      handler() {
        this.$forceUpdate();
      },
    },
    "pedido.id_sistema": function () {
      //this.buscarPedido();
    },
    tipo_entrega: function () {},
  },
  methods: {
    validaCPF() {
      this.cpfValido = null;
      if (this.pedido.cpf && this.pedido.cpf != "") {
        if (
          !validador.string.validarCPF(this.pedido.cpf) &&
          !validador.string.validarCNPJ(this.pedido.cpf)
        ) {
          this.$swal(
            "CPF/CNPJ INVÁLIDO!",
            "CPF informado está incorreto!",
            "error"
          );
          this.cpfValido = false;
        } else {
          this.cpfValido = true;
        }
      }
    },
    openCupom(pedido) {
      this.CupomOpen = pedido;
      this.$bvModal.show("modal-fiscal");
    },
    async selectValorMoto(e) {
      if (e && e.target) this.cod_moto = e.target.value;
      // console.log("selectingmoto",this.cod_moto,e.target.value);
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.pedido.cod_moto && this.motosDisponiveisRaw) {
            let fid = this.motosDisponiveisRaw.find(
              (x) => x.cod_moto == this.cod_moto
            );
            //  console.log('fnd', fid);
            if (fid) {
              this.pedido.vl_entrega_moto = this.formatFloatToReal(
                fid.vl_entrega
              );
              this.$forceUpdate();
              // console.log('vl', this.vl_entrega_moto)
            }
          }
        }, 20);
      });
    },
    async carregarMotos() {
      try {
        let res = await EntregasLib.getMotosDisponiveis({
          data: moment(
            this.pedido && this.pedido.data ? this.pedido.data : ""
          ).format("YYYY-MM-DD HH:mm:ss"),
        });
        // console.log("MotosCarregadas", res);
        if (res && res.success) {
          this.motosDisponiveisRaw = res.data;
          this.motosDisponiveis = res.data
            .filter((x) => x.cod_entrada)
            .map((it) => ({
              value: it.cod_moto,
              text: it.nome,
            }));
        }
      } catch (err) {
        console.log("Erro on get Motos", err);
      }
    },
    removerProblema() {
      if (this.pedido && this.pedido.pedido_erro) {
        this.pedido.pedido_erro = 0;
        this.pedido.motivo_erro = "";
        this.salvar();
      }
    },
    loginResult(result) {
      this.loginAdm = false;
      this.authAdm = !result ? false : result ? true : false;
    },
    solicitarLoginAdm() {
      if (!this.authAdm || this.authAdm == false) {
        this.loginAdm = true;
        return false;
      } else {
        return true;
      }
    },
    async cancelarPedido() {
      if (
        this.pedido &&
        this.pedido.cod_pedido &&
        this.pedido.cod_pedido != "" &&
        this.solicitarLoginAdm() === true
      ) {
        await this.$swal({
          title: "Confirmação",
          text: "Você tem certeza que deseja cancelar este pedido?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Sim! Cancelar.",
          cancelButtonText: "Não",
        }).then(async (rep) => {
          if (rep.isConfirmed) {
            let result = (await PedidoLib.cancelarPedido(this.pedido)).data;
            if (result.success) {
              this.$swal("Pedido cancelado!", "", "success");
              this.reset();
              this.$emit("closeModal");
            } else {
              alert("erro ao cancelar");
            }
          }
        });
      }
    },
    custom(vl) {
      // console.log("custom", vl);
      vl;
    },
    gotoFocus(txt) {
      this.$refs[txt].focus();
    },
    async buscarPedido() {
      if (
        !this.$props.pedidoOpen ||
        this.$props.pedidoOpen.id_sistema != this.pedido.id_sistema
      ) {
        this.loadingID = true;
        this.retornoID = await PedidoLib.buscarID(
          this.pedido.id_sistema,
          this.pedido.cod_empresa
        );
        this.loadingID = false;
      }
    },
    reset() {
      if (!this.empresaSelecionada) {
        this.empresaSelecionada = this.$store.state.empresa_ativa;
      }
      this.pedido.id_sistema = "";
      this.pedido.cod_pedido = "";
      this.pedido.tipo_entrega = "ENTREGA";
      this.pedido.bairro = "";
      this.pedido.complemento = "";
      this.pedido.alerta = false;
      this.pedido.observacoes = "";
      this.pedido.cod_pedido = "";
      this.pedido.cod_empresa = this.empresaSelecionada.cod_empresa;
      this.pedido.cod_caixa = this.$store.state.caixa_ativo.cod_caixa;
      this.pedido.senha = "";
      this.pedido.nome = "";
      this.pedido.pedido_erro = null;
      this.pedido.motivo_erro = null;
      this.pedido.imprimir = true;
      this.tipo_entrega = this.opcoes_entrega.find(
        (x) => x.titulo == "ENTREGA"
      );
      this.pagamento.vl_dinheiro = "0,00";
      this.pagamento.vl_total = "0,00";
      this.pagamento.vl_entrega = this.vl_entrega_padrao;
      this.pagamento.vl_cartao = "0,00";
      this.pagamento.vl_troco = "0,00";
      this.pagamento.vl_pago = 0;
      this.pagamento.forma = 4;
      this.pedido.data = moment().format("YYYY-MM-DD HH:mm:ss");

      this.pedido.vl_entrega_moto = this.formatFloatToReal(
        this.empresaSelecionada &&
          this.empresaSelecionada.configuracoes &&
          this.empresaSelecionada.configuracoes.frete_pg_online
          ? this.empresaSelecionada.configuracoes.frete_pg_online
          : 0
      );
      this.pedido.cupomFiscal = false;

      this.$forceUpdate();
    },
    selectEmpresa(emp) {
      this.empresaSelecionada = emp;
      this.pedido.cod_empresa = emp.cod_empresa;
      // if (emp.cod_empresa == 4) {
      //   emp.configuracoes.frete_padrao = 11;
      // }
      console.log(emp.configuracoes);
      if (emp && emp.configuracoes && emp.configuracoes.frete_online) {
        this.vl_entrega_padrao = this.formatFloatToReal(
          +emp.configuracoes.frete_online
        );

        if (this.pedido.tipo_entrega == "ENTREGA") {
          this.pagamento.vl_entrega = this.formatFloatToReal(
            +emp.configuracoes.frete_online
          );
          this.pedido.vl_entrega_moto = this.formatFloatToReal(
            emp.configuracoes.frete_pg_online
          );
        }
      } else {
        this.pedido.vl_entrega_moto = this.formatFloatToReal(0);
        this.pagamento.vl_entrega =
          this.tipo_entrega && this.tipo_entrega.titulo == "ENTREGA"
            ? this.vl_entrega_padrao
            : "0,00";
      }
    },
    focus(ref) {
      setTimeout(() => {
        this.$refs[ref].select();
      }, 100);
    },
    verLog() {
      console.log("openlog");
      this.$bvModal.show("modal-log");
    },
    escolherTipoEntrega(opc) {
      // console.log("opc", this.pagamento.vl_entrega);

      this.tipo_entrega = opc;
      this.pedido.tipo_entrega = opc.titulo;
      this.pagamento.vl_entrega_moto = this.formatFloatToReal(0);
      this.pagamento.vl_entrega =
        this.tipo_entrega && this.tipo_entrega.titulo == "ENTREGA"
          ? this.vl_entrega_padrao
          : "0,00";
      // console.log("opc", opc, this.pagamento.vl_entrega);
      this.$forceUpdate();
    },
    async salvar() {
      this.loadingSave = true;
      await this.$nextTick();
      this.erros = [];
      if (this.retornoID && this.retornoID.qt && this.retornoID.qt > 0) {
        this.erros.push("Código já utilizado");
      }
      if (
        !this.pedido.id_sistema ||
        this.pedido.id_sistema.toString().trim() == ""
      ) {
        this.erros.push("Codigo");
      }
      if (
        !this.pedido.tipo_entrega ||
        this.pedido.tipo_entrega.toString().trim() == ""
      ) {
        this.erros.push("Tipo de Entrega");
      }

      if (!this.pedido.bairro || this.pedido.bairro.toString().trim() == "") {
        this.erros.push("Bairro");
      }
      if (!this.pagamento.vl_total) {
        this.erros.push("Valor");
      }
      if (
        this.pedido.tipo_entrega != "BUSCAR" &&
        this.toVl(this.pagamento.vl_total) != this.pagamento.vl_pago
      ) {
        this.erros.push("Pagamento");
      }

      if (this.erros.length > 0) {
        let errors = "";
        this.erros.forEach((er) => {
          errors += "<li class='text-left'>" + er + "</li>";
        });
        this.$swal.fire({
          titulo: "Atenção!",
          icon: "error",
          html:
            "Campos abaixo precisam de atenção e/ou são obrigatórios <ul>" +
            errors +
            "</ul>",
        });
      } else {
        let ob = {
          ...this.pedido,
          pagamento: this.pagamento,
        };
        // console.log("obj", ob);
        try {
          if (ob.motivo_erro) {
            ob.pedido_erro = 1;
          } else {
            ob.pedido_erro = 0;
          }
          let result = await PedidoLib.storeOnline(ob);
          console.log("result", result);
          if (result.success) {
            this.loadingSave = true;
            await this.$nextTick();
            this.$swal
              .fire({
                title: "Pedido salvo com sucesso!",
                text: "SENHA " + result.data.senha,
                icon: "success",
                timer: 2500,
              })
              .then(async () => {
                if (this.pedido.cupomFiscal) {
                  this.CupomOpen = result.data;
                  await this.$nextTick();
                  this.openCupom(result.data);
                  console.log("foi", this.CupomOpen);
                }
                {
                  this.reset();
                  if (this.$props.pedidoOpen) {
                    this.$emit("closeModal");
                  }
                }
              });
          } else {
            this.loadingSave = false;
            await this.$nextTick();
            this.$swal.fire({
              title: "Erro ao salvar!",
              icon: "error",
              timer: 2500,
            });
          }
        } catch {
          this.loadingSave = false;
          await this.$nextTick();
          this.$swal.fire({
            title: "Erro ao salvar!",
            icon: "error",
            timer: 2500,
          });
        }
        this.loadingSave = false;
      }
      this.loadingSave = false;
    },
    toVl(vl) {
      if (!vl) vl = "0";
      return parseFloat(vl.toString().replace(".", "").replace(",", "."));
    },
    toMoney(vl) {
      if (!vl || vl == null || vl == null) {
        vl = 0;
      }
      return parseFloat(vl).toFixed(2).replace(",", "").replace(".", ",");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/pedido.scss";
.list-group-item:hover {
  background-color: #ccc !important;
}
.list-group-item {
  .hover {
    background-color: #ccc !important;
  }
  .selected {
    background-color: #ccc !important;
  }
}
</style>
