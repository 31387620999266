var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('empresa-combo',{attrs:{"selecionar":_vm.selectEmpresa,"selected":_vm.empresaSelecionada.cod_empresa}})],1),_c('div',{staticClass:"col-12 col-sm-6"},[_c('div',{},[_c('label',[_vm._v("Código")]),_c('b-input',{ref:"txtCodigo",class:{
                'text-danger': _vm.retornoID && _vm.retornoID.qt && _vm.retornoID.qt > 0,
              },attrs:{"type":"number","autocomplete":"off"},on:{"keypress":(e) => {
                  if (e.key == 'Enter') {
                    this.gotoFocus('txtTotal');
                  }
                }},model:{value:(_vm.pedido.id_sistema),callback:function ($$v) {_vm.$set(_vm.pedido, "id_sistema", $$v)},expression:"pedido.id_sistema"}}),(_vm.loadingID)?_c('div',[_c('b-spinner',{attrs:{"small":"","variant":"secondary"}}),_vm._v(" aguarde... ")],1):_vm._e(),(
                !_vm.loadingID && _vm.retornoID && _vm.retornoID.qt && _vm.retornoID.qt > 0
              )?_c('div',{staticClass:"text-danger"},[_vm._v(" Número já utilizado ")]):_vm._e()],1),_c('div',[_c('div',{staticClass:"mt-2 tipo-pedido"},_vm._l((_vm.opcoes_entrega),function(opc){return _c('label',{key:opc.titulo + _vm.opcoes_entrega.indexOf(opc),class:{ active: _vm.tipo_entrega == opc },staticStyle:{"width":"42%"},on:{"click":function($event){return _vm.escolherTipoEntrega(opc)}}},[_c('img',{attrs:{"src":require(`@/assets/img/icones/pedido/${opc.img}`)}}),_c('br'),_vm._v(" "+_vm._s(opc.titulo)+" ")])}),0)])]),_c('div',{staticClass:"col-12 col-sm-6"},[_c('div',{},[_c('label',[_vm._v("Total")]),_c('input',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"},{name:"model",rawName:"v-model",value:(_vm.pagamento.vl_total),expression:"pagamento.vl_total"}],ref:"txtTotal",staticClass:"form-control text-center",attrs:{"autocomplete":"off"},domProps:{"value":(_vm.pagamento.vl_total)},on:{"focus":function($event){return _vm.focus('txtTotal')},"keypress":(e) => {
                  if (e.key == 'Enter') {
                    this.gotoFocus('txtEntrega');
                  }
                },"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pagamento, "vl_total", $event.target.value)}}})]),_c('div',{},[_c('label',[_vm._v("Entrega")]),_c('input',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"},{name:"model",rawName:"v-model",value:(_vm.pagamento.vl_entrega),expression:"pagamento.vl_entrega"}],ref:"txtEntrega",staticClass:"form-control text-center",attrs:{"autocomplete":"off","readonly":""},domProps:{"value":(_vm.pagamento.vl_entrega)},on:{"focus":function($event){return _vm.focus('txtEntrega')},"keypress":(e) => {
                  if (e.key == 'Enter') {
                    this.gotoFocus('vlDim');
                  }
                },"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pagamento, "vl_entrega", $event.target.value)}}})])]),_c('div',{staticClass:"col-12 mb-3"},[_c('div',[_c('label',[_vm._v("Nome")]),_c('b-input',{ref:"txtNome",model:{value:(_vm.pedido.nome),callback:function ($$v) {_vm.$set(_vm.pedido, "nome", $$v)},expression:"pedido.nome"}})],1)]),_c('div',{staticClass:"col-12 mb-3"},[_c('div',[_c('label',[_vm._v("Bairro")]),_c('vue-simple-suggest',{attrs:{"list":_vm.simpleSuggestionList,"styles":_vm.autoCompleteStyle,"destyled":false,"filter-by-query":true,"max-suggestions":5},nativeOn:{"keypress":function($event){return ((e) => {
                  if (e.key == 'Enter') {
                    this.gotoFocus('txtComp');
                  }
                }).apply(null, arguments)}},model:{value:(_vm.pedido.bairro),callback:function ($$v) {_vm.$set(_vm.pedido, "bairro", $$v)},expression:"pedido.bairro"}})],1)]),_c('div',{staticClass:"col-12 mb-3"},[_c('div',[_c('label',[_vm._v("Complemento")]),_c('b-input',{ref:"txtComp",model:{value:(_vm.pedido.complemento),callback:function ($$v) {_vm.$set(_vm.pedido, "complemento", $$v)},expression:"pedido.complemento"}})],1)]),(_vm.pedido.cupomFiscal)?_c('div',{staticClass:"col-12 mb-3"},[_c('div',[_c('label',[_vm._v("CPF/CNPJ")]),_c('b-input',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##', '##.###.###/####-##']),expression:"['###.###.###-##', '##.###.###/####-##']"}],ref:"txtCPF",attrs:{"state":_vm.cpfValido},on:{"blur":_vm.validaCPF},model:{value:(_vm.pedido.cpf),callback:function ($$v) {_vm.$set(_vm.pedido, "cpf", $$v)},expression:"pedido.cpf"}})],1)]):_vm._e()])]),_c('div',{staticClass:"col-12 col-lg-6"},[_c('forma-pagamento',{attrs:{"pagamento":_vm.pagamento,"semPagamento":_vm.pedido.tipo_entrega == 'BUSCAR'}}),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-12"},[_c('label',[_vm._v("Observações")]),_c('label',{staticClass:"float-right"},[_c('b-check',{attrs:{"switch":""},model:{value:(_vm.pedido.alerta),callback:function ($$v) {_vm.$set(_vm.pedido, "alerta", $$v)},expression:"pedido.alerta"}},[_vm._v(" Notificar ")])],1),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.pedido.observacoes),expression:"pedido.observacoes"}],staticClass:"form-control",domProps:{"value":(_vm.pedido.observacoes)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pedido, "observacoes", $event.target.value)}}})])]),(
          _vm.pedido &&
          _vm.pedido.cod_pedido &&
          (_vm.$store.state.auth.user.tipo.indexOf('Admin') >= 0 ||
            _vm.$store.state.auth.user.tipo.indexOf('Gerente') >= 0)
        )?_c('div',{staticClass:"titulo mt-4"},[_vm._m(0),_c('div',{staticClass:"combo",staticStyle:{"max-width":"160px"}},[_c('b-select',{attrs:{"options":[
              '',
              'Não Atende',
              'Pedido Errado',
              'Reclamou Temperatura',
              'Faltou Itens',
              'Outros',
            ]},model:{value:(_vm.pedido.motivo_erro),callback:function ($$v) {_vm.$set(_vm.pedido, "motivo_erro", $$v)},expression:"pedido.motivo_erro"}})],1)]):_vm._e(),(
          _vm.pedido &&
          _vm.pedido.cod_pedido &&
          (_vm.$store.state.auth.user.tipo.indexOf('Admin') >= 0 ||
            _vm.$store.state.auth.user.tipo.indexOf('Gerente') >= 0)
        )?_c('div',{staticClass:"titulo mt-4"},[_vm._m(1),_c('div',{staticClass:"combo d-flex",staticStyle:{"max-width":"260px"}},[_c('b-select',{attrs:{"options":_vm.motosDisponiveis,"label":"nome"},nativeOn:{"change":function($event){return ((e) => _vm.selectValorMoto(e)).apply(null, arguments)}},model:{value:(_vm.pedido.cod_moto),callback:function ($$v) {_vm.$set(_vm.pedido, "cod_moto", $$v)},expression:"pedido.cod_moto"}}),_c('b-btn',{attrs:{"variant":"none","size":"sm"},on:{"click":function($event){return _vm.$bvModal.show('modal-view-motos')}}},[_c('b-icon-search')],1),_c('b-modal',{attrs:{"id":"modal-view-motos","hide-footer":"","size":"xl"},on:{"hidden":_vm.carregarMotos}},[_c('EntregasMotos',{attrs:{"data":_vm.pedido.data,"empresa":_vm.empresaSelecionada}})],1)],1)]):_vm._e(),(
          _vm.pedido &&
          _vm.pedido.cod_pedido &&
          (_vm.$store.state.auth.user.tipo.indexOf('Admin') >= 0 ||
            _vm.$store.state.auth.user.tipo.indexOf('Gerente') >= 0)
        )?_c('div',{staticClass:"titulo mt-4"},[_vm._m(2),_c('div',{staticClass:"combo",staticStyle:{"max-width":"100px"}},[_c('input',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"},{name:"model",rawName:"v-model",value:(_vm.pedido.vl_entrega_moto),expression:"pedido.vl_entrega_moto"}],ref:"txtVlEntregaMoto",staticClass:"form-control text-center",attrs:{"autocomplete":"off"},domProps:{"value":(_vm.pedido.vl_entrega_moto)},on:{"focus":function($event){return _vm.focus('txtVlEntregaMoto')},"keypress":(e) => {
                if (e.key == 'Enter') {
                  this.gotoFocus('txtVlEntregaMoto');
                }
              },"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pedido, "vl_entrega_moto", $event.target.value)}}})])]):_vm._e(),(_vm.pedido && _vm.pedido.pedido_erro && _vm.pedido.pedido_erro == 1)?_c('b-btn',{attrs:{"block":"","variant":"danger"},on:{"click":_vm.removerProblema}},[_c('i',{staticClass:"fa fa-times mr-1"}),_vm._v("Remover Problema")]):_vm._e()],1)]),_c('hr'),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-12 col-lg-5 text-right d-flex"},[(!_vm.pedidoOpen)?_c('b-check',{attrs:{"switch":"","size":"lg","value":"true"},model:{value:(_vm.pedido.imprimir),callback:function ($$v) {_vm.$set(_vm.pedido, "imprimir", $$v)},expression:"pedido.imprimir"}},[_vm._v(" Imprimir ")]):_vm._e(),(!_vm.pedidoOpen)?_c('b-check',{staticClass:"ml-3",attrs:{"switch":"","size":"lg","value":"true"},model:{value:(_vm.pedido.cupomFiscal),callback:function ($$v) {_vm.$set(_vm.pedido, "cupomFiscal", $$v)},expression:"pedido.cupomFiscal"}},[_vm._v(" Cupom Fiscal ")]):_vm._e(),(_vm.pedidoOpen)?_c('print-buttons',{staticClass:"text-left",attrs:{"noStyle":true,"codPedido":_vm.pedidoOpen.cod_pedido,"empresa":_vm.pedidoOpen.cod_empresa}}):_vm._e()],1),_c('div',{staticClass:"col-12 col-lg-3 mt-2 mt-lg-0"},[(
          _vm.pedido && _vm.pedido.cod_pedido && _vm.pedido.cod_pedido != '' && !_vm.authAdm
        )?_c('b-btn',{attrs:{"block":"","variant":"info"},on:{"click":_vm.solicitarLoginAdm}},[_c('b-icon-file-lock2'),_vm._v(" Autorização ADM")],1):_vm._e(),(_vm.pedido && _vm.pedido.cod_pedido && _vm.pedido.cod_pedido != '')?_c('b-btn',{attrs:{"variant":"danger","block":""},on:{"click":_vm.cancelarPedido}},[_c('b-icon-trash'),_vm._v(" Cancelar Pedido")],1):_vm._e(),(_vm.pedido && _vm.pedido.cod_pedido > 0)?_c('b-button',{staticClass:"mt-2",attrs:{"block":""},on:{"click":function($event){return _vm.verLog()}}},[_c('b-icon-clock-history',{staticClass:"mr-1"}),_vm._v(" Alterações ")],1):_vm._e()],1),_c('div',{staticClass:"col-12 col-lg-4 text-right mt-2 mt-lg-0"},[_c('b-button',{staticClass:"w-50",attrs:{"variant":"primary","disabled":_vm.loadingSave},on:{"click":_vm.salvar}},[(_vm.loadingSave)?_c('span',[_c('b-spinner',{attrs:{"small":"","size":"sm"}}),_vm._v(" salvando... ")],1):_c('span',[_c('i',{staticClass:"fa fa-save"}),_vm._v(" Salvar")])]),_c('b-button',{staticClass:"w-50",attrs:{"variant":"secondary"},on:{"click":() => {
            this.reset();
            this.$emit('closeModal');
          }}},[_c('i',{staticClass:"fa fa-ban"}),_vm._v(" Fechar ")]),(_vm.pedido.cod_pedido)?_c('b-btn',{staticClass:"mt-2",attrs:{"variant":"info","block":""},on:{"click":function($event){return _vm.openCupom(_vm.pedido)}}},[_c('i',{staticClass:"fa fa-receipt"}),_vm._v(" Cupom Fiscal")]):_vm._e()],1)]),(_vm.loginAdm)?_c('login-admin',{attrs:{"idExterno":_vm.pedido ? _vm.pedido.cod_pedido : null,"tipo":'pedido',"obs":_vm.pedido && _vm.pedido.cod_pedido
        ? 'Edição de pedido'
        : 'Autorização de pedido novo'},on:{"result":_vm.loginResult,"setSolicitacaoID":function($event){_vm.solicitacao_id = $event}}}):_vm._e(),_c('b-modal',{attrs:{"hide-footer":"","id":"modal-log","size":"xl","title":"Histórico de Alterações"}},[_c('pedido-log',{attrs:{"pedido":_vm.pedido}})],1),(_vm.CupomOpen)?_c('b-modal',{attrs:{"id":"modal-fiscal","title":"Cupom Fiscal","hide-footer":"","size":"xl"}},[_c('CupomFiscal',{attrs:{"PedidoID":_vm.CupomOpen.cod_pedido,"CodEmpresa":_vm.CupomOpen.cod_empresa}})],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"texto pl-2"},[_c('i',{staticClass:"fas fa-ban"}),_vm._v(" Problema no Pedido ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"texto pl-2"},[_c('i',{staticClass:"fa fa-motorcycle"}),_vm._v(" Moto")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"texto pl-2"},[_c('i',{staticClass:"fa fa-motorcycle"}),_vm._v(" Valor Pg. Moto ")])
}]

export { render, staticRenderFns }