<template>
  <div>
    <b-card no-body>
      <b-tabs
        pills
        vertical
        active-tab-class="m-0 p-0 t1"
        content-class="p-3 pr-4 t1"
        nav-wrapper-class="pr-0 t1"
        nav-item-class="text-left t1"
        active-nav-item-class="text-left t1 tt"
        v-model="pagamento.forma"
        style="font-size:0.8rem !important"
      >
        <b-tab title="Não Informado" v-if="semPagamento">
          <template #title>
            <div class="text-left">
              <i class="fa fa-times" />
              Não Informado
            </div>
          </template>
          <b-card-text>Basta finalizar o pedido</b-card-text>
        </b-tab>
        <b-tab title="Dinheiro">
          <template #title>
            <div class="text-left">
              <i class="fa fa-money-bill-wave" /> Dinheiro
            </div>
          </template>
          <b-card-text>
            <label>Valor Dinheiro</label>
            <b-input
              placeholder="valor"
              v-money="money"
              class="text-center"
              v-model="pagamento.vl_dinheiro"
              ref="vlDim"
              @focus="focus('vlDim')"
            />
            <label>Troco</label>
            <span class="form-control text-center">{{
              pagamento.vl_troco | currency
            }}</span>
          </b-card-text>
        </b-tab>
        <b-tab title="Cartão">
          <template #title>
            <div class="text-left">
              <i class="fa fa-credit-card" />
              Cartão
            </div>
          </template>

          <b-card-text>
            <label>Valor Cartão</label>
            <b-input
              placeholder="valor"
              v-money="money"
              class="text-center"
              v-model="pagamento.vl_cartao"
              ref="vlCart"
              @focus="focus('vlCart')"
            />
          </b-card-text>
        </b-tab>
        <b-tab title="Dinheiro + Cartão">
          <template #title>
            <div class="text-left">
              <i class="fa fa-money-check-alt" />
              Din. + Cartão
            </div>
          </template>
          <b-card-text>
            <label>Valor Dinheiro</label>
            <b-input
              placeholder="valor"
              v-money="money"
              class="text-center"
              v-model="pagamento.vl_dinheiro"
              ref="vlDim2"
              @focus="focus('vlDim2')"
            />
            <label>Valor Cartão</label>
            <b-input
              placeholder="valor"
              v-money="money"
              class="text-center"
              v-model="pagamento.vl_cartao"
              ref="vlCart2"
              @focus="focus('vlCart2')"
            />

            <label>Troco</label>
            <span class="form-control text-center">{{
              pagamento.vl_troco | currency
            }}</span>
          </b-card-text>
        </b-tab>
        <b-tab title="Vale">
          <template #title>
            <div class="text-left">
              <i class="fa fa-wallet" />
              Vale
            </div>
          </template>
          <b-card-text>Selecione o Cliente</b-card-text>
        </b-tab>
        <b-tab title="Online">
          <template #title>
            <div class="text-left">
              <i class="fa fa-globe" />
              Online
            </div>
          </template>
          <b-card-text>Basta finalizar o pedido</b-card-text>
        </b-tab>
        <b-tab title="Só Entregar">
          <template #title>
            <div class="text-left">
              <i class="fa fa-hand-holding" /> Só Entregar
            </div>
          </template>
          <b-card-text>Basta finalizar o pedido</b-card-text>
        </b-tab>
        <b-tab title="PIX">
          <template #title>
            <div class="text-left">
              <i class="fa fa-qrcode" />
              PIX
            </div>
          </template>
          <b-card-text>Basta finalizar o pedido</b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    semPagamento: {
      type: Boolean,
      default: false
    },
    pagamento: {
      type: Object,
      default() {
        return {
          vl_dinheiro: 0,
          vl_total: 0,
          vl_cartao: 0,
          vl_troco: 0,
          forma: 0,
          vl_entrega: 0,
          vl_pago: 0
        };
      }
    }
  },
  watch: {
    "pagamento.forma": {
      deep: true,
      handler() {
        // console.log(11);
        let contaPgto = this.semPagamento ? 1 : 0;
        if (this.pagamento.forma == contaPgto) {
          // console.log(1);
          this.pagamento.vl_cartao = "0,00";
          this.pagamento.vl_dinheiro = this.pagamento.vl_total;
        }
        if (
          this.pagamento.forma == contaPgto + 1 ||
          this.pagamento.forma == contaPgto + 2
        ) {
          // console.log(12);
          this.pagamento.vl_dinheiro = "0,00";
          this.pagamento.vl_cartao = this.pagamento.vl_total;
        }
        this.$forceUpdate();
      }
    },
    pagamento: {
      deep: true,
      handler() {
        // console.log("vl", this.pagamento);
        if (
          this.pagamento &&
          this.pagamento.vl_dinheiro &&
          this.pagamento.vl_cartao
        ) {
          let vl =
            parseFloat(
              this.pagamento.vl_dinheiro.replace(".", "").replace(",", ".")
            ) +
            parseFloat(
              this.pagamento.vl_cartao.replace(".", "").replace(",", ".")
            ) -
            parseFloat(
              this.pagamento.vl_total.replace(".", "").replace(",", ".")
            );
          if (vl < 0) vl = 0;
          this.pagamento.vl_troco = this.getValue(vl);
        }

        // if()

        this.pagamento.vl_pago = ["4", "5", "6", "7"].includes(
          this.pagamento.forma.toString()
        )
          ? this.toVl(this.pagamento.vl_total)
          : this.toVl(this.pagamento.vl_dinheiro) -
            this.toVl(this.pagamento.vl_troco) +
            this.toVl(this.pagamento.vl_cartao);

        this.$forceUpdate();
      }
    }
  },
  data() {
    return {
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */
      }
    };
  },
  methods: {
    getValue(vl) {
      if (!vl || vl == null) {
        vl = 0;
      }
      return parseFloat(vl)
        .toString()
        .replace(",", "")
        .replace(",", ".");
    },
    focus(inp) {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs[inp].select();
        }, 100);
      });
    },
    toVl(vl) {
      if (!vl || vl == null || vl == "") vl = "0";
      return parseFloat(vl.replace(".", "").replace(",", "."));
    }
  }
};
</script>

<style></style>
